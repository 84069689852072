/* eslint-disable jsx-a11y/alt-text */
import TriangleBg from "./../assets/triangle-bg.svg";
import VijayPhoto from "./../assets/vijay_photo.png";
import { LinkedinFilled } from "@ant-design/icons";
import { Button, Carousel } from "antd";
import React from "react";
const students = [
    {
        src: VijayPhoto,
        name: "Vijayakumar Mathaiyan",
        job: "Senior Software Engineer - Zomenutm",
        review: "Crakkit's courses transformed my coding skills and helped me land my first tech job—hands-on projects, expert guidance, and real-world applications made all the difference!",
        linkedIn: "",
        facebook: ""
    },
    {
        src: VijayPhoto,
        name: "Thanigaivel",
        job: "Senior Software Engineer - Zomenutm",
        review: "The course offered clear explanations, hands-on practice, and a solid foundation in data structures and algorithms, making complex topics easier to understand.",
        linkedIn: "",
        facebook: ""
    },
    {
        src: VijayPhoto,
        name: "Kalaiyarsan",
        job: "Senior Software Engineer - Zomenutm",
        review: "The interactive coding challenges were especially helpful in solidifying my understanding of data structures and algorithms.",
        linkedIn: "",
        facebook: ""
    }
];
export default function Students() {
    return (
        <section
            className="students content-h-mt w-full relative flex flex-col items-center justify-center"
            id="feedbacks"
        >
            <div className="text-3xl md:text-4xl font-semibold">
                <span className="text-blue-700">What</span> our students say
            </div>
            <div className="w-full mx-10 py-5 mt-5 mb-10">
                <Carousel autoplay autoplaySpeed={5000} speed={1300}>
                    {students.map((student) => (
                        <div key={student.name}>
                            <div className="flex flex-col items-center my-10 p-5 pt-10 md:p-10 shadow-xl rounded-md border bg-white w-80 md:w-2/3 mx-auto relative">
                                <span className="absolute -top-6 left-0 md:-left-6 text-slate-900 text-9xl font-extrabold z-10 font-serif">
                                    &ldquo;
                                </span>
                                <span className="text-3xl italic text-gray-500">
                                    {student.review}
                                </span>
                                <div className="flex hidden justify-center items-start ml-auto mt-4 md:mt-3">
                                    <span className="text-2xl text-gray-400 mr-3 mt-1 hidden md:inline">
                                        -
                                    </span>
                                    <img
                                        src={student.src}
                                        className="rounded-full h-10 w-10 overflow-hidden"
                                    />
                                    <div className="flex flex-col justify-center ml-2">
                                        <span className="text-base font-medium flex items-center">
                                            {student.name}{" "}
                                            <Button
                                                type="link"
                                                className="h-auto p-0 ml-1"
                                            >
                                                <LinkedinFilled className="text-xl font-semibold p-0" />
                                            </Button>
                                        </span>
                                        <span className="text-sm text-gray-500">
                                            {student.job}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
            <img
                src={TriangleBg}
                className="absolute w-1/4 right-30 top-10 rotate-45 md:top-0 -z-10 text-gray-100"
            />
            <img
                src={TriangleBg}
                className="absolute w-1/4 left-30 bottom-20 md:bottom-5 -z-10 -rotate-180 text-gray-100"
            />
        </section>
    );
}
