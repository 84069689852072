/* eslint-disable jsx-a11y/alt-text */
import TriangleBg from "./../assets/triangle-bg.svg";
import React from "react";
const whyUSList = [
    {
        key: "Top-Tier Mentors",
        value: "Learn from experts at leading product companies."
    },
    {
        key: "Hands-On Learning",
        value: "80% of courses is hands on coding."
    },
    {
        key: "Curated Problem Sets",
        value: "Master key patterns & challenges."
    },
    {
        key: "Career Boost",
        value: "Profile building & exclusive networking."
    },
    {
        key: "Personalised Guidance",
        value: "1:1 mentoring sessions."
    },
    {
        key: "Mock Interviews",
        value: "Sharpen your interview skills."
    },
    {
        key: "Smart Support",
        value: "Automated & fully trackable support."
    }
];
export default function About() {
    return (
        <section
            className="content-h-mt content-width relative flex flex-col justify-center items-center"
            id="about"
        >
            <div className="text-3xl md:text-4xl font-semibold">
                What is <span className="text-blue-700">Crakkit?</span>
            </div>
            <div className="text-xl md:text-2xl mt-6 md:mt-10 text-center mx-8 md:mx-30 font-medium text-gray-500">
                At Crakkit, we are driven by a mission to equip college students
                with industry-relevant coding skills that lead to high-paying
                jobs. Whether you're preparing for a college placement or aiming
                to launch a career in tech, we provide AI-driven, personalized
                learning that helps you excel.
            </div>
            <div className="flex flex-col gap-2 justify-center items-start mt-10">
                {whyUSList.map((data) => {
                    return (
                        <div>
                            <span className="text-lg md:text-2xl font-semibold">
                                {data.key}
                            </span>{" "}
                            :
                            <span className="text-lg md:text-2xl font-medium text-gray-500 ml-2">
                                {data.value}
                            </span>
                        </div>
                    );
                })}
            </div>
            <img
                src={TriangleBg}
                className="absolute w-1/4 right-30 top-20 md:top-10 -z-10 text-gray-100"
            />
            <img
                src={TriangleBg}
                className="absolute w-1/4 left-30 bottom-10 md:bottom-5 -z-10 -rotate-180 text-gray-100"
            />
        </section>
    );
}
