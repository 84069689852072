import { createContext } from "react";
import React from "react";
import AmazonCompany from "./../assets/companies/amazon.png";
import GrowfinCompany from "./../assets/companies/growfin.png";
import TCSCompany from "./../assets/companies/tcs.png";
import ZomentumCompany from "./../assets/companies/zomentum.png";
import ZohoCompany from "./../assets/companies/zoho.png";

export const AppContext = createContext(null);
export const menuItems = [
    {
        key: "courses",
        title: "Courses"
    },
    {
        key: "mentors",
        title: "Mentors"
    },
    {
        key: "about",
        title: "About"
    },
    {
        key: "contact-us",
        title: "Contact Us"
    }
];
export const extraMenuItems = [
    {
        key: "feedbacks",
        title: "Reviews"
    },
    {
        key: "faqs",
        title: "FAQs"
    }
];

export const companies = [
    {
        title : "Amazon",
        src: AmazonCompany,
        className: "max-h-13"
    },
    {
        title : "Growfin",
        src: GrowfinCompany,
        className: "max-h-10"
    },
    {
        title : "TCS",
        src: TCSCompany,
        className: "max-h-13"
    },
    {
        title : "Zomentum",
        src: ZomentumCompany,
        className: "max-h-13"
    },
    {
        title : "Zoho",
        src: ZohoCompany,
        className: "max-h-13"
    }
]