/* eslint-disable jsx-a11y/alt-text */
import TriangleBg from "./../assets/triangle-bg.svg";
import { Collapse } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import React from "react";
const faqs = [
    {
        question: "Are there any assessments or exams in the course?",
        answer: "Yes, The course includes quizzes, coding assignments, a mid-term exam, and a final project to assess your understanding and ability to apply the concepts learned throughout the course."
    },
    {
        question: "How will this course help me in my career?",
        answer: "By learning data structures and algorithms, You will improve your problem-solving and coding efficiency, making you better prepared for technical interviews and enhancing your ability to work on complex software development projects."
    },
    {
        question:
            "Will this course cover advanced algorithms and data structures?",
        answer: "Yes, The course will cover both fundamental and advanced topics, including binary trees, graphs, dynamic programming, and advanced data structures like tries and segment trees."
    },
    {
        question: "Do you offer any career guidance programme?",
        answer: "Yes, Our industry experts will connect with you and provide you guidance to successed in your career."
    },
    {
        question: "Where to see course price?",
        answer: "Please register using the join now link, our team will get in touch."
    },
    {
        question: "Do you take college placement training?",
        answer: "Yes, We take college placement training. Please register using the join now link, our team will get in touch."
    }
];
export default function FAQ() {
    const items = faqs.map((data, key) => {
        return {
            key,
            label: data.question,
            children: data.answer
        };
    });
    return (
        <section
            className="faqs min-content-h w-full relative flex flex-col items-center mb-10"
            id="faqs"
        >
            <div className="text-2xl md:text-4xl font-semibold">
                Frequently Asked{" "}
                <span className="text-blue-700">Questions</span>
            </div>
            <div className="w-full md:w-2/3 py-5">
                <Collapse
                    accordion
                    defaultActiveKey={["0"]}
                    ghost
                    items={items}
                    className="mx-10 md:auto"
                    expandIconPosition="end"
                    expandIcon={(props) => {
                        return (
                            <span className="p-1 rounded-full border border-solid border-gray-50">
                                <ArrowUpOutlined
                                    className={`${
                                        props.isActive ? "rotate-180" : ""
                                    } transition-transform ease-in-out text-gray-50`}
                                />
                            </span>
                        );
                    }}
                />
            </div>
            {/* <img
                src={TriangleBg}
                className="absolute w-1/4 right-30 top-20 md:top-10 -z-10 text-gray-100"
            />
            <img
                src={TriangleBg}
                className="absolute w-1/4 left-30 bottom-10 md:bottom-5 -z-10 -rotate-180 text-gray-100"
            /> */}
        </section>
    );
}
