import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NavBar from "./components/NavBar";
import About from "./pages/About";
import { useEffect } from "react";
import { useContactUs } from "./pages/ContactUs";
import { AppContext } from "./components/AppData";
import { message } from "antd";
import React from "react";

function App() {
    useEffect(() => {
        function handleScrollEffect() {
            document.body.style.setProperty(
                "--scroll",
                (window.scrollY || window.pageYOffSet) /
                    (document.body.offsetHeight - window.innerHeight)
            );
        }
        window.addEventListener("scroll", handleScrollEffect, false);
        return () => {
            window.removeEventListener("scroll", handleScrollEffect, false);
        };
    }, []);
    const { contactUsModal, handleContactUsModalVisiblity } = useContactUs();
    const [messageApi, contextHolder] = message.useMessage();
    const appData = {
        handleContactUsModalVisiblity,
        messageApi
    };
    return (
        <section className="w-full relative">
            <AppContext.Provider value={appData}>
                {contextHolder}
                <NavBar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                </Routes>
                {contactUsModal}
            </AppContext.Provider>
        </section>
    );
}

export default App;
