/* eslint-disable jsx-a11y/alt-text */
import TriangleBg from "./../assets/triangle-bg.svg";
import BEDev from "./../assets/cources/be-dev.svg";
import FEDev from "./../assets/cources/fe-dev.svg";
import DSADev from "./../assets/cources/dsa.svg";
import FullsatckDev from "./../assets/cources/fullstack-dev.svg";
import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useContext } from "react";
import { AppContext } from "./../components/AppData";
import React from "react";

const courses = [
    {
        src: DSADev,
        title: "Data Structures And Algorithms",
        desc: "Get a deep dive into fundamental data structures and algorithms, emphasizing problem-solving, efficiency analysis, and practical implementation."
    },
    {
        src: BEDev,
        title: "BackEnd Development",
        desc: "Understand the basics of data structures and algorithm with your own comfortable language"
    },
    {
        src: FEDev,
        title: "FrontEnd Development",
        desc: "Master React JS with  hands-on course, designed to equip you with the skills to build dynamic, high-performance web applications and excel in frontend development."
    },
    {
        src: FullsatckDev,
        title: "Fullstack Development",
        desc: "Our comprehensive course, covering both frontend and backend technologies to build dynamic, scalable web applications from start to finish"
    }
];
export default function Courses() {
    const { handleContactUsModalVisiblity } = useContext(AppContext);
    return (
        <section
            className="content-h-mt w-full relative flex flex-col md:justify-between items-center"
            id="courses"
        >
            <div
                className="text-2xl md:text-4xl font-semibold mt-5"
                key="course-title"
            >
                Our Most Popular <span className="text-blue-700">Courses</span>
            </div>
            <div
                className="text-lg md:text-xl font-medium text-gray-800 mt-5 w-3/4 md:w-[65%] text-center"
                key="course-desc"
            >
                Our Most Popular Courses Our courses are designed by industry
                experts to ensure you master the most in-demand technologies.
                With Crakkit, you're not just learning to code—you're learning
                to thrive in the fast-evolving tech world.
            </div>
            <div
                className="w-full flex overflow-x-auto justify-around py-5 md:py-10"
                key="course-list"
            >
                {courses.map((course) => (
                    <div
                        className="flex flex-col items-center mx-3 shadow-xl flex-shrink-0 rounded-lg border bg-white w-65 md:w-75"
                        key={course.name}
                    >
                        <img
                            src={course.src}
                            className="rounded-full overflow-hidden min-h-20"
                        />
                        <div className="flex-grow mt-3 px-5">
                            <div className="text-lg font-semibold ">
                                {course.title}
                            </div>
                            <div className="text-base font-medium text-gray-500 mt-3">
                                {course.desc}
                            </div>
                        </div>
                        <div className="flex justify-start items-center flex-grow-0 mt-3 mb-5">
                            <Button
                                type="link"
                                onClick={() =>
                                    handleContactUsModalVisiblity(true)
                                }
                                className="font-semibold"
                            >
                                {/* Brochure <DownloadOutlined />{" "} */}
                                Enquire Now
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
            <Button type="link" className="mt-5 font-semibold hidden">
                Explore More Courses
            </Button>
            {/* <img
                src={TriangleBg}
                className="absolute w-1/4 -right-30 top-20 md:-top-30 -z-10 text-gray-100"
            />
            <img
                src={TriangleBg}
                className="absolute w-1/4 left-30 bottom-10 md:-bottom-20 -z-10 -rotate-180 text-gray-100"
            /> */}
        </section>
    );
}
