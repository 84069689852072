/* eslint-disable jsx-a11y/alt-text */
import TriangleBg from "./../assets/triangle-bg.svg";
import VijayPhoto from "./../assets/vijay_photo.png";
import KalaiPhoto from "./../assets/kalai.jpeg";
import ThanigaiPhoto from "./../assets/thanigai.jpeg";
import SenthilPhoto from "./../assets/senthil_c.jpeg";
import { LinkedinFilled, FacebookFilled } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
const instructors = [
    {
        src: KalaiPhoto,
        name: "Kalaiyarsan",
        job: "Senior Software Engineer",
        company: "Thoughtworks | Ex-Zoho",
        linkedIn: "https://www.linkedin.com/in/kalaiyarasan-raja-3b8761168/",
        facebook: ""
    },
    {
        src: SenthilPhoto,
        name: "Senthil C",
        job: "Senior Software Engineer",
        company: "Walmart | Ex-Ola",
        linkedIn: "https://www.linkedin.com/in/senthil-c/",
        facebook: ""
    },
    {
        src: ThanigaiPhoto,
        name: "Thanigaivel Muthusamy",
        job: "Senior Software Engineer - UI",
        company: "Aviso AI | Ex-Zoho",
        linkedIn:
            "https://www.linkedin.com/in/thanigaivel-muthusamy-94b112117/",
        facebook: ""
    },
    {
        src: VijayPhoto,
        name: "Vijayakumar Mathaiyan",
        job: "Senior Software Engineer",
        company: "Zomenutm | Ex-Zoho",
        linkedIn: "https://www.linkedin.com/in/vijayakumar-madhaiyan/",
        facebook: ""
    }
];
export default function Instructors() {
    return (
        <section
            className="content-h-mt w-full relative flex flex-col items-center"
            id="mentors"
        >
            <div className="text-3xl md:text-4xl font-semibold mt-5">
                Meet the <span className="text-blue-700">Mentors</span>
            </div>
            <div className="text-lg md:text-xl font-medium text-gray-800 mt-5 w-3/4 md:w-2/3 text-center">
                Meet our expert instructors—industry leaders from top tech
                companies, passionate about sharing their real-world experience
                and guiding you to success in coding, development, and career
                growth.
            </div>
            <div className="w-full flex justify-around py-10 overflow-x-auto">
                {instructors.map((instructor) => (
                    <div
                        className="flex flex-col items-center p-5 shadow-xl rounded-lg border bg-white max-h-80 w-70 mx-3 flex-shrink-0  md:w-80 "
                        key={instructor.name}
                    >
                        <img
                            src={instructor.src}
                            className="rounded-full h-25 w-25 md:h-30 md:w-30 overflow-hidden"
                        />
                        <span className="text-xl md:text-2xl font-semibold mt-3">
                            {instructor.name}
                        </span>
                        <span className="text-lg md:text-xl font-medium text-gray-500 mt-3">
                            {instructor.job}
                        </span>
                        <span className="text-base md:text-lg  text-gray-500 mt-1">
                            {instructor.company}
                        </span>
                        <div className="flex justify-center items-center mt-5">
                            <Button
                                type="link"
                                className="text-2xl font-semibold p-0"
                                onClick={() => window.open(instructor.linkedIn)}
                            >
                                <LinkedinFilled />
                            </Button>
                            {/* <Button
                                type="link"
                                className="text-2xl font-semibold ml-3 p-0"
                            >
                                <FacebookFilled />
                            </Button> */}
                        </div>
                    </div>
                ))}
            </div>
            {/* <img
                src={TriangleBg}
                className="absolute w-1/4 -right-30 rotate-45 scale-125 top-20 md:top-10 -z-10 text-gray-100"
            />
            <img
                src={TriangleBg}
                className="absolute w-1/4 left-30 rotate-45 -bottom-10 md:bottom-5 -z-10 -rotate-180 text-gray-100"
            /> */}
        </section>
    );
}
